@import url('https://fonts.googleapis.com/css2?family=Noto+Serif:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cinzel&display=swap');
@import url('https://fonts.googleapis.com/css2?family=EB+Garamond&display=swap');

:root{
  --primary: #FF7b7b;
  --secondary: #61677A;
  --error: #D83F31;
  --background: #D8D8D8;
  --head: #272829;
}
.header {
  font-family: 'Cinzel', serif;
  font-size: 14px;
  display: flex; /* Apply flexbox */
  justify-content: center; /* Horizontally center the logo */
  align-items: center; /* Vertically center the logo */
  padding-top: 30px;
  color: var(--head);
}

.logo {
  max-width: 3rem;
  height: auto;
  border-radius: 50%;
}
.visual-diary-link {
  text-decoration: none;
  color: var(--head);
}
.visual-diary-link:visited {
  color: inherit; /* Maintain the same color as the parent */
}

body{
  font-family: 'EB Garamond', serif;
  color: var(--secondary);
  background-color: var(--background);
}
.App{
  max-width: 960px;
  margin: 0 auto;
}
.title p{
  text-align: center;
}


/* upload form styles */
form {
  margin: 30px auto 10px;
  text-align: center;
}

label input {
  height: 0;
  width: 0;
  opacity: 0;
}

label {
  display: inline-block; 
  width: 40px; 
  height: 40px;
  border: 2px solid var(--primary);
  border-radius: 5px; 
  margin: 10px auto;
  text-align: center; 
  line-height: 40px; 
  font-weight: bold;
  font-size: 10px; /* Decrease font size for smaller icon */
}

label:hover {
  background: var(--primary);
  color: white;
}

.output {
  height: 60px;
  font-size: 0.8rem;
}


/* progress bar styles */
.progress-bar{
  height: 5px;
  background: var(--primary);
  margin-top: 20px;
}

/* image grid styles */
.img-grid {
  margin: 20px auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 40px;
}

.img-wrap {
  overflow: hidden;
  position: relative;
  border-radius: 5%;
  width: 100%; 
  height: 300px; 
}

.img-wrap img {
  width: 100%;
  height: 100%; 
  display: block;
  object-fit: cover; 
  transition: transform 0.3s ease; 
}

@media (max-width: 768px) {
  .img-grid {
    grid-gap: 20px;
    grid-template-columns: 1fr 1fr; 
  }
  .img-wrap {
    height: 200px;
  }
}


/* modal styles */
.backdrop{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.5);
}
.backdrop img{
  display: block;
  max-width: 60%;
  max-height: 80%;
  margin: 60px auto;
  box-shadow: 3px 5px 7px rgba(0,0,0,0.5);
  border: 3px solid white;
}